import { productPlaceholder } from '@assets/placeholders'
import { convertProduct } from '@framework/utils/convert-product'
import isEmpty from 'lodash/isEmpty'
import { formatProductName } from '@utils/format-product-name'
import { getCurrentPrice } from './helper'
export interface Item {
  id: string | number
  name: string
  slug: string
  image: {
    thumbnail: string
    [key: string]: unknown
  }
  price: number
  sale_price?: number
  quantity?: number
  [key: string]: unknown
  extension_attributes?: any
  click_and_collect?: string
}
interface Variation {
  id: string | number
  title: string
  price: number
  sale_price?: number
  quantity: number
  [key: string]: unknown
}
export function generateCartItem(item: Item, variation?: Variation) {
  const {
    id,
    name,
    slug,
    price,
    sale_price,
    quantity,
    unit,
    sku,
    gallery,
    brand,
    case_quantity,
    category_l1,
    category_l2,
    category_l3,
    category_l4,
    attributes,
    click_and_collect,
    wishlist_item_id,
    is_addtocart,
    flags,
    shipping_type,
    temperature_description,
    product_id,
    objectID,
    special_price,
    special_from_date,
    special_to_date,
    ships_in_days,
    entity_id,
    is_in_stock,
    weight,
    width,
    height,
    length,
    ship_weight,
    ups_ship_weight,
    ups_ship_width,
    ups_ship_height,
    ups_ship_length,
    default_vendor_code,
    weight_unit,
    dimension_unit,
  } = convertProduct(item)
  let formattedName = formatProductName(
    name,
    brand,
    attributes?.case_description,
    case_quantity,
  )

  if (!isEmpty(variation)) {
    return {
      id: `${id}.${variation.id}`,
      productId: id,
      sku,
      name: `${name} - ${variation.title}`,
      slug,
      unit,
      stock: variation.quantity,
      price: variation.sale_price ? variation.sale_price : variation.price,
      image:
        (item['image.thumbnail'] || gallery?.[0]?.thumbnail || item?.image) ??
        productPlaceholder,
      variationId: variation.id,
      brand,
      category: category_l1,
      category_l2: category_l2,
      category_l3: category_l3,
      category_l4: category_l4,
      case_quantity: case_quantity,
      click_and_collect,
      special_price,
      special_from_date,
      special_to_date,
      is_in_stock,
    }
  }
  const priceDetails = {
    special_price,
    special_from_date,
    special_to_date,
    price,
  }
  return {
    id,
    quantity,
    formattedName,
    name,
    sku,
    slug,
    unit,
    image:
      (item['image.thumbnail'] || gallery?.[0]?.thumbnail || item?.image) ??
      productPlaceholder,
    stock: quantity,
    price: getCurrentPrice(priceDetails),
    brand,
    category: category_l1,
    category_l2: category_l2,
    category_l3: category_l3,
    category_l4: category_l4,
    case_quantity: case_quantity,
    click_and_collect,
    flags,
    shipping_type,
    temperature_description,
    product_id,
    objectID,
    wishlist_item_id,
    is_addtocart,
    special_price,
    special_from_date,
    special_to_date,
    ships_in_days,
    entity_id,
    is_in_stock,
    attributes,
    weight,
    width,
    height,
    length,
    ship_weight,
    ups_ship_weight,
    ups_ship_width,
    ups_ship_height,
    ups_ship_length,
    default_vendor_code,
    weight_unit,
    dimension_unit,
  }
}
