export const SESSION_STATUS = Object.freeze({
  AUTH: 'authenticated',
  UNAUTH: 'unauthenticated',
})

export const CART_STATUS = Object.freeze({
  EMPTY: 'EMPTY_CART',
  ADDED: 'ADDED_ITEM',
  YOUR_CART: 'YOUR_CART',
})

export const CART_SERVICE = Object.freeze({
  POSTAL_CODE: 'selectedPostalCode',
})

export const WEIGHT_UNITS = Object.freeze({
  LBS: 'LBS',
  KG: 'KG',
})

export const DIMENSION_UNITS = Object.freeze({
  INCH: 'IN',
  CM: 'CM',
})

export const CONVERSION_FACTORS = Object.freeze({
  KG_TO_LBS: 2.20462,
  CM_TO_INCH: 1 / 2.54,
})

const SETTINGS = {
  shipping: {
    DEFAULT_POST_CODE: '',
    DEFAULT_REGION: '',
    DEFAULT_COUNTRY_ID: 'CA',
    ADDRESS_TYPE: 'bus',
  },
}

export const PAYMENT_METHOD = Object.freeze({
  FREE: 'free',
  BRAINTREE_CREDITCARD: 'braintree',
  BRAINTREE_SAVEDCARD: 'braintree_cc_vault',
  BRAINTREE_PAYPAL: 'braintree_paypal',
  AFTERPAY: 'afterpay',
  BRAINTREE_APPLEPAY: 'braintree_applepay',
  CREDIT_CARD: 'Credit Card',
  STORE_CREDIT: 'No Payment Information Required',
  STORED_CARDS: 'Stored Cards (Braintree)',
})

export const CARRIER_CODE_UPS = 'ups'
export const CARRIER_CODE_PUROLATOR = 'purolator'
export const FREE_SHIPPING = 'Free Shipping'
export const CANADIAN_POSTAL_CODE_REGEX = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/
export const EMAIL_REGEX =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
export const PICK_UP = 'pickup'
export const DELIVERY = 'delivery'
export const SHIPPING = 'shipping'
export const BILLING = 'billing'
export const CREDITCARD = 'creditcard'
export const LOCAL_INV_FLAG = 'local_inv'
export const CART_SIDEBAR = 'CART_SIDEBAR'
export const REFRIGERATED = 'Refrigerated'
export const FROZEN = 'Frozen'
export const ITEM_ADDTOCART_MAX_LIMIT = 2000
export const ITEM_DOESNOT_EXIST_ERROR =
  "The product that was requested doesn't exist. Verify the product and try again."

export const frenchTranslations: any = {
  Bakery: 'Boulangerie',
  'Bar / Club / Entertainment': 'Bar / Club / Divertissement',
  'Business Office': "Bureau d'entreprise",
  Butcher: 'Boucher',
  Caterer: 'Traiteur',
  'Coffee Shop': 'Café',
  'Convenience Store': 'Dépanneur',
  Deli: 'Deli',
  'Doctor’s Offices': 'Cabinet Médical',
  'Event Planner': "Organisateur d'Événements",
  'Food Truck': 'Food truck',
  'Gas Station': 'Station-service',
  'Municipality / Government': 'Municipalité / Gouvernement',
  'Grocery / Retail Store': 'Épicerie / Commerce de détail',
  'Home Use (Consumer)': 'Usage Domestique (Consommateur)',
  'Hospital / Clinic': 'Hôpital / Clinique',
  'Hotel / B&B': 'Hôtel',
  Manufacturer: 'Manufacturier',
  Military: 'Armée',
  'Recreational Centre': 'Centre de loisirs',
  'Religious Location': 'Lieu de culte',
  'Restaurant – (Chain)': 'Restaurant - (Chaîne)',
  'Restaurant – (Independent)': 'Restaurant - (Indépendant)',
  Retailer: 'Détaillant',
  'Retirement / Nursing Home': 'Maison de retraite / Maison de soins',
  'School / Day Care': 'École / Garderie',
  Wholesale: 'Vente en gros',
  'Winery / Tasting Bar': 'Vignoble / Bar de dégustation',
  'Other Business': 'Autres activités',
}

export default SETTINGS
